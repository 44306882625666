<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="客服二维码" class="flex-def">
              <y_upload_img v-model="form.waiter"></y_upload_img>
            </el-form-item>
            <el-form-item label="客服链接" class="flex-def">
              <el-input v-model="form.waiter_link"></el-input>
            </el-form-item>
            <el-form-item label="仅免单卡会员可获取海报" class="flex-def">
              <el-switch v-model="form.poster_must_member"></el-switch>
            </el-form-item>
            <el-form-item label="活动规则">
              <br>
              <y_editor v-model="form.rule" eid="rule"></y_editor>
            </el-form-item>
            <el-form-item label="活动说明">
              <br>
              <y_editor v-model="form.desc" eid="desc"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="背景色">
              <y_choose_color v-model="form.bg_color"></y_choose_color>
            </el-form-item>
            <el-form-item label="使用流程">
              <br>
              <y_editor v-model="form.help" eid="help"></y_editor>
            </el-form-item>
            <el-form-item label="常见问答">
              <br>
              <y_editor v-model="form.qa" eid="qa"></y_editor>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_choose_color from "@/components/y-form/y_choose_color";
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {
    y_editor,
    y_choose_color,
    y_upload_img,
  },
  data() {
    return {
      form: {
        bg_color: "",
        waiter: "",
        rule: "",
        desc: "",
        help: "",
        qa: "",
        waiter_link:"",
        poster_must_member:false,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.privilegeCard.conf().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.shop.plugin.privilegeCard.confEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>